import { useNamespace } from 'components/templates/Translation/TranslationWrapper';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import React from 'react';

export interface MetaData {
  title: string;
  description: string;
  imageUrl?: string;
  isTranslated?: true;
}

export interface Props {
  metaData?: MetaData;
  shouldIndexPage?: boolean;
  locales?: string[];
}

export default function PageMetaData({ metaData, shouldIndexPage, locales }: Props) {
  const namespace = useNamespace();
  const { t } = useTranslation(namespace);
  const title = t(`${metaData?.title || 'Welcome'} | LifePoints`);
  const description = t(metaData?.description || 'Welcome to LifePoints');

  return (
    <>
      {metaData && (
        <Head>
          <title>{`${title}`}</title>
          <meta name="description" content={description} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          {metaData.imageUrl && <meta property="og:image" content={metaData.imageUrl} />}
        </Head>
      )}

      {shouldIndexPage !== undefined && (
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
      )}
    </>
  );
}
